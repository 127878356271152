/* Brand */

#brand {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  background-attachment: fixed;


  .overlay {
    background-color: rgba(0, 0, 0, 0.8); } }

#brand-slide {
  .slide-thumb {
    height: 160px;
    width: 160px;

    img {
      width: 100%;
      height: auto; } }

  .owl-nav {
    display: none; } }

