#finance {
  padding: 70px 0;
  text-align: center;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
  background-attachment: fixed;


  .finance-content {
    color: #c2c2c2;
    @include line-height(26);
    margin-bottom: 60px; } }



.finance-title {
  @include font-size(30);
  color: #FFF;
  font-weight: 700;
  margin-top: 0; }



.counter {
  .count-item {
    height: 160px;
    width: 160px;
    border-left: 2px solid $color_theme;
    border-top: 2px solid $color_theme;
    border-bottom: 2px solid $color_theme;
    position: relative;
    p {
      @include font-size(50);
      color: #FFF;
      font-weight: 700;
      margin: 43px 20px 43px 0; }


    span {
      @include font-size(14);
      position: absolute;
      color: #FFF;
      right: -45px;
      top: 46%; }

    &:before {
      position: absolute;
      content: '';
      background: $color_theme;
      height: 50px;
      width: 2px;
      top: 0;
      right: 0; }

    &:after {
      position: absolute;
      content: '';
      background: $color_theme;
      height: 50px;
      width: 2px;
      bottom: 0;
      right: 0; } } }
