/* Page Banner */
.page-banner {
  padding: 265px 0 150px;
  text-align: center;
  position: reletive;
  background-size: cover;
  background-repeat: no-repeat;

  .banner-title {
    h1 {
      @include font-size(32);
      color: #FFF;
      font-weight: 700;
      text-transform: uppercase; }
    p {
      color: #FFF;
      text-transform: uppercase;
      font-weight: 700;

      span {
        color: $color_theme; } } } }


