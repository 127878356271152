/* Footer */
#footter {
  background: #111; }

.footer-wrapper {
  padding: 50px 0;

  .dt-social-link {
    float: none;
    margin-left: -10px;
    margin-top: 20px;
    li {
      padding: 0 15px; } }


  .brand-logo {
    margin-bottom: 25px; }

  p {
    color: #a9a9a9;
    font-weight: 700; } }

.address {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    color: #FFF;
    padding: 10px 0;

    i {
      padding-right: 5px; } } }


.footer-title {
  color: #FFF;
  margin-top: 0;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #2c2a2a;
  margin-bottom: 20px; }

.dt-service-menu {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 13px 0;
    border-bottom: 1px solid #2c2a2a;

    &:last-child {
      border-bottom: none; }

    a {
      color: #FFF;
      display: inline-block;
      @include font-size(16);
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      i {
        margin-right: 10px; }

      &:hover {
        color: $color_theme; } } } }

.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 10px;

    a {
      color: #FFF;
      font-size: 16px;
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }

.footer-news {
  margin-top: 40px; }

.news-item {
  margin-bottom: 25px;
  a {
    text-decoration: none; }

  img {

    float: left; }

  .news-content {
    margin-left: 80px;
    margin-top: -7px;

    p {
      color: #a9a9a9;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 6px; }

    span {
      color: $color_theme;
      font-weight: 700; } } }

.copyright-bar {
  background: #000;
  padding: 25px 0;
  text-align: center;

  p {
    margin-bottom: 0;
    a {
      text-decoration: none; } } }

