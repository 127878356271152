#feedback {
  text-align: center;
  padding: 100px 0;
  background-size: cover;
  background-attachment: fixed;

  .section-title {
    color: #FFF;
    margin-bottom: 50px;

    &:after {
      left: 42%; } } }


.feedback-slider {
  .item {
    overflow: hidden;
    margin-right: 40px;

    .cliten-thumbs {
      float: left;
      .thumbs {

        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 1px solid #FFF;
        padding: 8px;

        img {
          display: block;
          width: 83px;
          height: auto;
          border-radius: 50%; } }
      h4 {
        color: #FFF; } } }

  .content {
    float: right;
    background-color: rgba(255, 255, 255,0.302);
    padding: 30px;
    color: #FFF;
    text-align: left;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 12px solid rgba(255, 255, 255,0.302);
      border-bottom: 10px solid transparent;
      left: -12px;
      top: 25%; }

    p {
      color: #FFF; } } }


.owl-theme {
  .owl-nav {
    display: none; }

  .owl-dots {
    margin-top: 50px !important;
    position: relative;

    .owl-dot {

      span {
        width: 14px;
        height: 6px;
        border-radius: 0; }


      &:hover,
      &.active {
        span {
          background: $color_theme; } } } } }

.feedback-slider-two {
  .item {

    .content {
      margin-bottom: 30px;

      p {
        @include line-height(26); }

      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid rgba(255, 255, 255, 0.302);
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%); } }

    .cliten-thumbs {
      display: inline-block;
      float: none;

      .thumbs {
        margin: 0 auto;
        padding: 6px;

        img {
          width: 86px; } } } } }
