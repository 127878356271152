/* Our Feature Two */
#feature-two {
  .feature {
    border: 1px solid #d9d9d9;
    transform: translateY(-40%);
    z-index: 200;
    box-shadow: 0px -20px 36px 4px rgba(0, 0, 0, 0.2);

    .section-caption {
      margin-top: -80px; }

    .feature-box {
      background: #f5f5f7;
      padding: 40px 0;
      border-right: 1px solid #d9d9d9;
      cursor: pointer;

      i {
        font-size: 50px;
        color: #d9d9d9;
        margin-bottom: 20px;
        transition: all 0.3s ease-in-out; }

      h4 {
        font-size: 26px;
        font-weight: 700;
        color: #111; }

      p {
        margin-bottom: 25px;
        color: #6d6c6c; }

      .btn-skew {
        &:after {
          height: 62px; }

        &:hover {
          &:after {
            height: 120px; } } }

      &:hover {
        i {
          color: $color_theme; } } } }



  .feature-item {
    .section-caption {
      margin-top: -80px; }
    .featured {
      margin-bottom: 65px; } }

  .featured {

    .feature-content {
      margin-bottom: 0px;
      margin-left: 108px; } }

  .featured-right {
    .item-icon {
      float: right; }

    .feature-content {
      margin-right: 108px;
      margin-left: 0;
      text-align: right; } } }

.section-caption {
  margin-bottom: 50px;
  text-align: center;

  .title {
    font-size: 36px;
    color: #111;
    font-weight: 700;
    span {
      font-weight: 400;
      text-transform: initial; } }

  p {
    color: #6d6c6c;
    @include line-height(26); } }


