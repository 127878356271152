#feature-three {
  text-align: center;
  padding: 100px 0;

  .section-title {
    &:after {
      left: 37%; } }

  .feature-item {
    .featured {
      padding: 35px;
      margin-bottom: 0px;
      border-bottom: 1px solid #ededed;
      border-right: 1px solid #ededed;

      .item-icon {
        float: none;
        margin-bottom: 30px; }

      .feature-content {
        margin-left: 0; } } } }

.boder-bottom-none {
  border-bottom: none !important; }

.border-right-none {
  border-right: none !important; }

