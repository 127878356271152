/* News All Two */
.blog-two {
  padding: 50px 0; }

.blog-item-two {
  .news {
    .news-thumbs {
      float: left; }
    .news-post {
      margin-left: 420px;
      margin-bottom: 30px;

      h3 {
        @include font-size(20);
        margin: 10px 0;

        a {
          color: #111;
          text-decoration: none;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $color_theme; } } } }


    .news-content {
      padding: 26px 0;
      border-right: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      border-top: 1px solid #ededed;

      p {
        margin-bottom: 30px; }

      .btn-skew {
        &:after {
          height: 65px; }

        &:hover {
          &:after {
            height: 150px; } } } } } }

.post-date {
  color: $color_theme;
  display: inline-block;

  i {
    padding-right: 10px; }

  &:hover {
    text-decoration: none;
    color: $color_theme; } }


.news-details {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 0;
  margin: 0;
  list-style: none;

  li {
    display: inline-block;
    padding-right: 5px;

    a {
      color: #111;
      text-decoration: none;

      i {
        padding-right: 5px; } }

    .dt-social-link {
      li {
        padding: 0 3px;

        a {
          color: #bdbdbd;

          &:hover {
            color: $color_theme; } } } } } }

