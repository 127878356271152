/* Banner */

.swiper-banner {
  position: relative;
  overflow: hidden;

  .swiper-slide [data-animate] {
    opacity: 0;
    transition-delay: 0.6s; }

  .swiper-slide-active [data-animate] {
    opacity: 1; }


  .swiper-slide {
    margin-top: 45px;

    > img {
      width: 100%;
      height: auto; }

    .slider-caption {
      position: absolute;
      top: 40%;
      left: 20%;
      h3 {
        @include font-size(20);
        color: #FFF;
        text-transform: uppercase;
        font-weight: 700;
        @include letter-spacing(600);
        margin-top: 0;
        text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
        margin-top: 0;
        margin-bottom: 30px; }

      h2 {
        @include font-size(50);
        color: #FFF;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
        margin-top: 0;
        margin-bottom: 40px; }

      p {
        color: #FFF;
        @include line-height(26);
        margin-bottom: 50px; } } } }

.banner-next,.banner-prev {
  top: 50%;
  position: absolute;
  z-index: 500;
  color: #FFF;
  font-size: 30px;
  cursor: pointer; }

.banner-next {
  right: 5%;
  border-right: 1px solid #FFF;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  transition: all 0.3s ease;

  &:before, &:after {
    content: '';
    position: absolute;
    background: #FFF;
    width: 1px;
    height: 13px;
    transition: all 0.3s ease; }

  &:before {
    top: 0;
    left: 0; }

  &:after {
    bottom: 0;
    left: 0; }


  .next-btn {
    background-image: url(../img/right-arrow.svg);
    height: 40px;
    width: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateX(-15px);
    background-position: center center;
    transition: all 0.3s ease-in-out; }

  &:hover {
    border-color: $color-theme;

    &:before, &:after {
      background: $color-theme; }

    .next-btn {
      transform: translateX(-5px);
      background-image: url(../img/right-arrow-two.svg); } } }

.banner-prev {
  left: 5%;
  border-left: 1px solid #FFF;
  border-top: 1px solid;
  border-bottom: 1px solid #FFF;
  transition: all 0.3s ease;

  &:before, &:after {
    content: '';
    position: absolute;
    background: #FFF;
    width: 1px;
    height: 13px;
    transition: all 0.3s ease; }

  &:before {
    top: 0;
    right: 0; }

  &:after {
    bottom: 0;
    right: 0; }

  .prev-btn {
    background-image: url(../img/left-arrow.svg);
    height: 40px;
    width: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translateX(15px);
    background-position: center center;
    transition: all 0.3s ease-in-out; }

  &:hover {
    border-color: $color-theme;
    &:before, &:after {
      background: $color-theme; }

    .prev-btn {
      transform: translateX(5px);
      background-image: url(../img/left-arrow-two.svg); } }

  .swiper-pagination-bullets {
    width: auto;
    right: 12%;
    left: auto;
    bottom: 25px;

    .swiper-pagination-bullet {
      width: 10px;
      border-radius: 0;
      background: #FFF;
      opacity: 1; }
    .swiper-pagination-bullet-active {
      background: $color-theme; } } }

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.5); }


/* Banner Two */

.home-two {
  .swiper-banner {
    .swiper-slide {
      margin-top: 0;

      .slider-caption {
        top: 30%;
        left: 30%;

        h3 {
          @include font-size(40);
          letter-spacing: 0;
          text-transform: none;
          margin-bottom: 15px; }

        h2 {
          @include font-size(60);
          margin-bottom: 35px; } } }

    .slider-control {
      position: absolute;
      top: 20%;
      left: 30%;
      width: 150px;

      &:after {
        content: '';
        position: absolute;
        background: #fff;
        height: 50px;
        width: 3px;
        z-index: 800;
        top: -4px;
        left: 73px; } } } }

/* Banner Three */
.home-three {

  .swiper-banner {
    .swiper-slide {
      margin-top: 0; } } }


