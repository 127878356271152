.dt-btn {
  border: 2px solid #FFF;
  color: #FFF;
  background: none;
  cursor: pointer;
  padding: 8px 50px;
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  text-decoration: none;
  z-index: 1;
  @include font-size(16);

  &:after {
    content: '';
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
    background: $color_theme;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    z-index: -1;
    position: absolute;
    transition:  all 0.5s ease; }

  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
    border-color: $color_theme;

    &:after {
      height: 200px;
      opacity: 1; } } }

.dt-btn-white {
  &:after {
    background: #FFF !important; }

  &:hover {
    text-decoration: none;
    color: #000 !important;
    border-color: #FFF; } }


.btn-skew {
  border: 2px solid $color_theme;
  &:after {
    content: '';
    width: 100%;
    height: 85px;
    top: 50%;
    left: 50%;
    background: $color_theme;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    z-index: -1;
    position: absolute;
    transition:  all 0.5s ease;
    opacity: 1; }

  &:hover {
    color: #fff;
    text-decoration: none;
    border-color: $color_theme;

    &:after {
      height: 200px; } } }

.sm-btn {
  padding: 6px 33px;
  @include font-size(14); }

.md-btn {
  padding: 11px 45px;
  @include font-size(14);
  text-transform: uppercase; }
