@media (max-width: 1440px) {
  // Feature
  .feature {
    h3 {
      @include font-size(20); }

    .overlay-content {
      .content {
        transform: translate(-30%,-50%);

        h2 {
          @include font-size(24);
          margin-bottom: 10px; }

        p {
          margin-bottom: 10px; }



        .dt-btn-white {
          padding: 10px 30px; } } } }

  /* Team Two */
  #team-two {
    .gallery-top {
      .banner-next, .banner-prev {
        right: 13%; } }

    .gallery-thumbs {
      right: 10%; } } }



@media (max-width: 1024px) {

  .section-title {
    font-size: 24px;
    padding-bottom: 10px; }

  .navbar {
    .navbar-nav {
      li {
        a {
          padding: 0 14px; } } } }

  /* Banner */
  .swiper-banner {
    .swiper-slide {
      .slider-caption {
        left: 15%;
        top: 34%;
        h3 {
          @include font-size(18);
          margin-bottom: 20px; }

        h2 {
          @include font-size(40);
          margin-bottom: 20px; }

        p {
          margin-bottom: 35px; } } }

    .banner-prev {
      left: 2%; }

    .banner-next {
      right: 2%; } }



  /* Home Two */
  .home-two {
    .swiper-banner {
      .swiper-slide {
        .slider-caption {
          left: 15%;
          top: 20%;
          h3 {
            @include font-size(24);
            margin-bottom: 10px; }

          h2 {
            @include font-size(30);
            margin-bottom: 10px; }

          p {
            margin-bottom: 10px; } } }

      .slider-control {
        left: 15%;
        top: 7%; } } }

  /* Our Feature */
  .feature-item {
    .featured {
      .feature-content {
        p {
          br {
            display: none; } } } } }



  /* Feature */
  .feature {
    h3 {
      @include font-size(14); }

    .overlay-content {
      .content {
        transform: translate(-48%, -50%);
        h2 {
          @include font-size(16);
          margin-bottom: 10px; }

        p {
          @include font-size(13);
          @include line-height(20);
          margin-bottom: 10px;

          br {
            display: none; } }

        .dt-btn-white {
          padding: 7px 20px; } } } }

  /* Feature Two*/
  #feature-two {
    .feature-item {
      .featured {
        margin-bottom: 30px; } } }

  /* Finance */
  .counter {
    .count-item {
      height: 130px;
      width: 130px;

      p {
        @include font-size(40);
        margin: 35px 35px 35px 0; }

      span {
        top: 41%; } } }

  /* Aboute */
  #about-us {
    padding-top: 30px;

    .section-title {
      margin-top: 0;
      margin-bottom: 20px; }

    img {
      width: 100%; }

    .skill-bar {
      p {
        margin-bottom: 35px;
        br {
          display: none; } }

      #skills {
        width: 425px;

        .skills {
          p {
            top: -60px; }

          span {
            top: -39px;
            @include font-size(13); } } } } }

  progress {
    margin-bottom: 15px;
    height: 20px; }

  /* Aboute Two */
  #aboute-two {
    padding: 15px 0; }

  .aboute-details {
    margin-bottom: 30px; }

  .aboute-caption {
    .aboute-title {
      margin-bottom: 20px; } }

  /*Choose Two*/
  .choose-thumb {
    transform: translateY(0);

    img {
      width: 100%; } }
  #choose {
    .content {
      p {
        br {
          display: none; } } } }

  /* Client Feedback */
  .feedback-slider {
    .item {
      .cliten-thumbs {
        float: none;

        .thumbs {
          margin: 0 auto; } } }

    .content {
      p {
        margin-bottom: 0;

        br {
          display: none; } } } }




  /* Team Two */
  #team-two {
    .gallery-top {
      .swiper-slide {
        img {
          width: 220px; }

        .slider-content {
          margin-left: 240px;

          h4 {
            margin-top: 0; }

          span {
            margin-bottom: 10px; }

          p {
            margin-bottom: 10px; } } }

      .banner-next, .banner-prev {
        right: 5%; }

      .banner-next {
        bottom: 15px; } }

    .gallery-thumbs {
      right: 2%; } }


  /* Service */
  #our-service {
    padding: 40px 0; }

  /* FeedBack */
  .feedback-slider {
    .item {
      margin-right: 6px; }

    .content {
      padding: 15px 10px; } }

  /* Team */

  .team {
    .team-member {
      .overlay {
        padding: 20px 10px;

        .name {
          h3 {
            font-size: 15px; } } } } }

  /* Team Details */
  .team-details {
    padding: 50px 0;
    .team-thumb {
      float: none; }

    .team-caption {
      margin-left: 0;
      margin-top: 30px; } }


  /* Blog */
  #letest-news {
    .news {
      .news-details {

        .details {
          padding-bottom: 5px;

          p, a {
            font-size: 24px; } } }


      .news-thumb {} }


    .news-caption {
      p {
        margin-bottom: 15px;

        br {
          display: none; } } } }

  /* Blog Two */
  .news-item-two {
    .news {
      .news-post {
        h3 {
          @include font-size(18); } }

      .news-content {
        padding: 9px 0; } } }

  /* Widgets */
  .widget_dt_search {
    input[type="search"] {
      width: 100% !important; } } }



@media (min-width: 800px) and (max-width: 992px) {
  /* Header */
  #navigation-menu {
    .brand-logo {
      margin-bottom: 0; } }

  .navbar {
    .navbar-nav {
      li {
        padding: 0; } } }

  /* Feedback */
  .feedback-slider {
    .item {
      .cliten-thumbs {
        float: none;
        .thumbs {
          margin: 0 auto; } } }

    .content {
      p {
        br {
          display: none; } } } }


  /* Feature */
  #feature-two {
    .feature {
      transform: translateY(0); }

    .feature-item {
      .section-caption {
        margin-top: 0; } } }

  /* Design */
  .tabs-items {
    border-right: none;
    border-bottom: 1px solid #ededed;

    .tabs {
      margin-bottom: 30px; } }
  .dt-tabs-nav {
    li {
      display: inline-block;
      margin-right: 30px; } }

  .tabs-content {
    padding: 0;

    .desing-caption {
      h3 {
        margin-top: 20px; } } } }


@media (max-width: 768px) {

  .return-to-top {
    bottom: 20px;
    right: 20px; }

  #dt-header {
    display: none; }

  .md-btn {
    padding: 7px 30px; }

  /* Mobile Menu */
  #mobile-wrapper {
    display: block; }

  #mobile-header {
    display: block;
    .menu-container {
      .menu-toggle {
        display: block; } }

    .brand-logo {
      width: 100px;
      display: block;
      img {
        width: 100%; } } }

  /* Banner */
  .swiper-banner {
    .swiper-slide {
      img {
        width: 100%; }

      .slider-caption {
        left: 6%;
        top: 15%;
        padding: 0 50px;

        h3 {
          @include font-size(15);
          margin-bottom: 10px; }

        h2 {
          @include font-size(30);
          margin-bottom: 15px; }

        p {
          margin-bottom: 15px;
          @include line-height(20);
          br {
            display: none; } } } }

    .dt-btn {
      padding: 7px 32px;
      @include font-size(14); }

    .btn-skew {
      &:after {
        height: 65px; }

      &:hover {
        &:after {
          height: 200px; } } } }

  .banner-prev {
    .prev-btn {
      height: 35px;
      width: 35px; } }

  .banner-next {
    .next-btn {
      height: 35px;
      width: 35px; } }

  /* Banner Two */
  .home-two {
    .swiper-banner {
      .slider-control {
        top: 5%;
        left: 7%;

        &:after {
          height: 40px;
          top: 0; } }

      .swiper-slide {
        .slider-caption {
          left: 1%;
          top: 22%;

          h3 {
            @include font-size(18);
            margin-bottom: 5px; }

          h2 {
            @include font-size(24);
            margin-bottom: 10px; }

          p {
            @include font-size(12);
            @include line-height(20); } } } } }

  /* banner Three */
  .home-three {
    .swiper-banner {
      .swiper-slide {
        .slider-caption {
          top: 10%;

          h3 {
            @include font-size(18);
            margin-bottom: 5px; }

          h2 {
            @include font-size(24);
            margin-bottom: 10px; } } } } }

  /* Feature */
  .feature {
    h3 {
      @include font-size(16); }

    .overlay-content {
      text-align: center;

      .content {
        h2 {
          @include font-size(20);
          margin-bottom: 10px; }

        p {
          @include font-size(15);
          br {
            display: block; } }



        .dt-btn-white {
          padding: 7px 20px; } } } }

  /* Feature Two */
  #feature-two {
    .feature-item {
      .section-caption {
        margin-top: 0; } }
    .feature {
      transform: translateY(0);

      .feature-box {
        border-bottom: 1px solid #c5c0c0; } } }



  /* Our Feature */
  .feature-item {
    .featured {
      margin-bottom: 50px;
      .feature-content {
        p {
          @include line-height(20);
          br {
            display: none; } } } }

    .feature-thumbs {
      text-align: center; } }

  /* Theme */

  #theme {
    .btn-skew {
      &:after {
        height: 65px; } } }



  /* Finance */
  .counter {
    .count-item {
      margin: 0 auto 30px; } }

  /* Aboute */
  #about-us {
    img {
      width: 100%; }

    .skill-bar {
      padding: 20px; } }



  /* About Two */

  #aboute-two {

    .btn-skew {
      &:after {
        height: 65px; } } }

  .aboute-caption {
    .aboute-title {
      @include font-size(30); } }


  /* Client */
  #client {
    padding: 30px 0;
    .swiper-slide {
      padding: 0 48px;

      .client-comments {
        padding: 10px; } }

    .section-caption {
      margin-bottom: 20px;

      .title {
        @include font-size(30);
        margin: 0; } } }


  /* Aboute */
  #about-us {
    padding: 0;
    .skill-bar {
      #skills {
        width: 500px; } } }

  /* Feedback */

  #feedback {
    padding: 50px 0; }

  .feedback-slider {
    .item {
      margin: 0 auto;
      width: auto;

      .content {
        padding: 20px; } } }

  /* Team */
  #team {
    padding: 50px 0;

    .team-member {
      margin-bottom: 20px;

      .overlay {
        .name {
          h3 {
            @include font-size(22); } } } } }


  /* Team Two */
  #team-two {

    .gallery-top {
      .banner-prev, .banner-next {
        transform: rotate(0deg);
        top: 90%; }

      .banner-prev {
        right: 25%; }

      .banner-next {
        right: 15%;
        bottom: 35px; }

      .swiper-slide {

        .slider-content {
          margin-left: 235px;
          h4 {
            margin-top: 0; }

          span {
            margin-bottom: 15px; }

          p {
            @include line-height(20);
            margin-bottom: 12px;

            br {
              display: none; } } } } }

    .gallery-thumbs {
      display: none; } }




  /* Call Back */
  #call-back {
    padding: 50px 0; }

  /* Choose */
  #choose-us {
    padding-top: 50px;

    img {
      display: none; } }

  .choose-item {
    margin-top: 0; }

  /* Theme */
  #theme {
    padding: 50px 0; }

  /* Design */
  .tabs-items {
    border-right: none;
    border-bottom: 1px solid #ededed;

    .tabs {
      margin-bottom: 30px; }



    .design {
      &:after {
        left: 36%; } } }


  .dt-tabs-nav {
    li {
      display: inline-block;
      margin-right: 30px; } }

  .tabs-content {
    padding: 0;

    .desing-caption {
      h3 {
        margin-top: 20px; } } }

  /* Blog */
  .news {
    .news-details {
      .details {
        padding-bottom: 10px;

        p, a {
          font-size: 28px; } } } }

  .news-item-two {
    .news {
      .news-thumbs {
        float: none;
        img {
          width: 100%; } }
      .news-post {
        margin-left: 0;
        margin-top: 20px; } } }

  /* Blog Three */
  .comments {
    .media-list {
      .media-list-child {
        padding-left: 100px; } } }



  /* Page Banner */
  .page-banner {
    padding: 100px 0; } }



@media (max-width: 500px) {
  .full-width {
    width: 100% !important;
    margin-bottom: 20px; }

  /* Banner */
  .banner {
    .swiper-banner {

      .swiper-slide {

        img {
          height: 460px;
          width: auto; }

        .slider-caption {
          left: 6%;
          top: 22%;
          padding: 0px 33px;

          h3 {
            @include font-size(13);
            margin-bottom: 5px; }

          h2 {
            @include font-size(22);
            margin-bottom: 10px; }

          p {
            @include line-height(20); } } }

      .dt-btn {
        padding: 6px 22px;
        @include font-size(12);
        margin-right: 10px; }

      .btn-skew {
        &:after {
          height: 49px; }

        &:hover {
          &:after {
            height: 200px; } } } }

    .banner-prev {
      .prev-btn {
        height: 30px;
        width: 30px; } }

    .banner-next {
      .next-btn {
        height: 30px;
        width: 30px; } } }

  /* Banner Two */
  .home-two {
    .swiper-banner {
      .swiper-slide {
        .slider-caption {
          top: 30%;
          left: 0; } }

      .slider-control {
        width: 120px;

        &:after {
          left: 60px;
          height: 35px; } } } }


  /* Feature */
  .feature {
    .overlay-content {
      .content {
        h2 {
          @include font-size(18);
          margin-bottom: 0; }

        p {
          @include line-height(24);
          @include font-size(14); }

        .dt-btn-white {
          padding: 7px 16px; } } } }

  .feture-thumb {
    img {
      width: 100%; } }

  /* Feature Three */
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          float: none;

          img {
            width: 100%; } }

        .feature-content {
          margin-left: 0;
          margin-top: 20px; } } } }

  /* Theme */
  #theme {
    .theme-thumb {
      img {
        width: 100%; } }

    .theme-title {
      @include font-size(22); } }



  /* About */
  #about-us {
    padding: 30px 0;

    .skill-bar {
      padding: 20px 0;

      #skills {
        width: 300px; } } }

  /* Design */
  .dt-tabs-nav {
    li {
      margin-right: 10px; } }

  .design-content {}


  .design-details {
    li {
      @include font-size(14); } }




  /* Service */
  #our-service {

    .section-title {

      &:after {
        left: 43%; } } }



  /* Feedback */
  #feedback {
    padding: 50px 0;

    .section-title {

      &:after {
        left: 43%; } } }

  .feedback-slider {
    .item {
      margin: 0 auto;


      .cliten-thumbs {
        float: none;

        .thumbs {
          margin: 0 auto; } }

      .content {
        p {
          margin-bottom: 0;

          br {
            display: none; } } } } }

  .owl-theme {
    .owl-nav {
      display: none; } }



  /* Team */
  #team {
    .section-title {

      &:after {
        left: 43%; } } }


  .team {
    .team-member {
      .overlay {
        padding: 20px 10px;
        top: 82%; } } }


  /* Choose */
  .category {
    .content {
      p {
        br {
          display: none; } } }

    .serial {
      display: none; }

    .icon-box {
      margin-left: 0; } }


  /* Team Two */
  #team-two {
    .gallery-top {
      .banner-next, .banner-prev {
        top: 95%; }

      .banner-next {
        right: 10%; }

      .swiper-slide {
        img {
          float: none;
          width: 100%;
          margin-bottom: 15px; }

        .slider-content {
          margin-left: 0 !important; } } } }


  /* News */
  #letest-news {
    .news {
      .news-details {
        width: 60px;
        .details {
          padding-bottom: 0px;

          p, a {
            font-size: 16px; }
          span {
            @include font-size(12); } } }

      .news-thumb {
        padding-left: 70px; } }

    .news-caption {
      margin-left: 30px;

      .news-title {
        @include font-size(16); } } }

  /* Page Banner */
  .page-banner {
    padding: 70px 0; }

  /* Insurance */
  .insurance-thumb {
    h2 {
      @include font-size(18); } }

  .insurance-content {

    .insu-thumb {

      li {
        margin-right: 8px; } } }

  .insu-support {
    .support {
      .support-logo {
        margin-left: 15px;
        margin-bottom: 20px; } } }

  /* Blog */
  .blog-all-item {
    .news {
      h3 {
        @include font-size(18); } } }

  /* Blog two */
  .blog-item-two {
    .news {
      .news-thumbs {
        float: none;
        margin-bottom: 20px;

        img {
          width: 100%; } }

      .news-post {
        margin-left: 0; } } }

  /* Blog Three */
  .comments {
    .media-list {
      .media {
        .media-inner {
          margin-bottom: 0; } }

      .media-body {
        .comment-date {
          li {
            padding-right: 10px; } } }

      .media-list-child {
        padding-left: 30px; } } }

  .media-left {
    a {
      width: 70px;
      height: 70px;
      display: block;


      img {
        width: 100%; } } } }








