/* Choose Two */
#choose {
  padding: 50px 0 0;

  .choose-caption {
    h1 {
      color: #111;
      font-weight: 700;
      @include font-size(36);
      margin-top: 0;
      margin-bottom: 30px;

      span {
        font-weight: 400; } }

    p {
      margin-bottom: 50px;
      @include line-height(26); } }

  .choose-item {
    margin-top: 0;

    .category {
      margin-top: 70px;

      .icon-box {
        margin-left: 0;
        margin-right: 0; } } }

  .content {
    margin-left: 100px;

    h3 {
      margin-top: 10px; } } }

.choose-thumb {
  margin-top: -75px; }

