/* Download Menu */
.download {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 10px 0;
    position: relative;
    cursor: pointer;
    display: block;
    a {
      @include font-size(14px);
      color: #6d6c6c;
      text-decoration: none;
      transition: all 0.3s ease;

      i {
        @include font-size(20);
        padding-right: 10px; } }
    span {
      position: absolute;
      right: 0;
      i {
        color: #6d6c6c;
        transition: all 0.3s ease; } }

    &:hover {
      a {
        color: $color_theme; }

      span {
        i {
          color: $color_theme; } } } } }

