/*  Navigatin Menu*/
#dt-header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  transition: all 0.5s ease; }

/* Top Menu */
.navbar-fixed-top {
  transform: translateY(-51px);
  background-color: rgb(37, 37, 37);
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);

  #navigation-menu {
    .navbar {
      .navbar-header {
        .brand-logo {
          padding: 10px 0;
          transition: all 0.3s ease-in-out; } }
      .navbar-nav {
        li {
          padding: 21px 0;

          &.border-right {
            &:before {
              top: 25px; } } } }

      .child-menu {
        li {
          padding: 10px 0; } } } } }


/* Main Menu */
#navigation-menu {
  background-color: rgba(255, 255, 255, 0.102);
 }  // border-bottom: 1px solid #656b70

.navbar-collapse {
  padding: 0; }
.navbar {
  height: auto;
  margin-bottom: 0;
  border: none;
  .navbar-header {
    .brand-logo {
      display: block;
      padding: 26px 0;
      transition: all 0.3s ease-in-out;

      .transparent-logo {
        display: block; }

      .normal-logo {
        display: none; } } }

  .navbar-nav {
    li {
      position: relative;
      padding: 38px 0;
      transition: all 0.5s ease-in-out;
      a {
        @include font-size(14);
        @include line-height(26);
        color: #FFF;
        padding: 0 25px;
        transition: all 0.3s ease-in-out;
        text-transform: uppercase;
        font-weight: 700;

        &:hover, &:focus {
          background-color: transparent;
          color: $color_theme; } }

      &.border-right {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          height: 14px;
          width: 1px;
          background: #FFF;
          top: 43px;
          right: 8px;
          transition: all 0.3s ease-in-out; } }



      /* search */
      .form {
        padding: 0; }

      .form-search {
        padding-right: 0;
        display: inline-block;
        position: relative;
        margin-left: 10px; }

      .form-search-input {
        width: 0;
        height: 20px;
        border: 0;
        font-size: 14px;
        padding: 0;
        transition: all 0.5s ease;
        height: 30px;
        margin-top: -4px;
        margin-left: 12px;
        background-color: transparent;
        color: #FFF; }

      .search-input-open {
        width: 150px;
        padding: 5px;
        display: initial;
        outline: none;
        background: transparent;
        border: 1px solid #FFF;
        margin-left: -7px; }



      .form-search-submit {
        display: inline-block;
        cursor:  pointer;
        text-align: center;
        padding: 0 5px;
        color: #FFF;
        position: absolute;
        top: 2px;
        right: 3px; }

      .child-menu {
        left: 0px;
        position: absolute;
        top: 102%;
        width: 200px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
        background: none 0px 0px repeat scroll rgb(37, 37, 37);
        list-style: none outside none;
        margin: 0px;
        padding: 0px;
        transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);

        li {
          padding: 10px 0;
          border-bottom: 1px solid #4e4e4e;
          transition: all 0.3s ease-in-out;

          &:last-child {
            border-bottom: none; }

          &:hover {
            background-color: #0074d8;
            a {
              color: #FFF;
              display: block;
              text-decoration: none; } } } }

      &:hover {

        .child-menu {
          transform: translateY(0px);
          visibility: visible;
          transition: all 0.5s ease-out;
          opacity: 1;
          z-index: 9999; } } } } }




.menu-toggle {
  position: relative;
  width: 30px;
  height: 23px;
  float: right;
  margin: 6px;
  display: none;

  &:hover {
    cursor: pointer; }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 1px;
    background-color: $color-theme;
    transition: all 0.5s ease-in-out;

    &:nth-child(2) {
      top: 50%;
      margin-top: -1px; }

    &:nth-child(3) {
      top: auto;
      bottom: 0; } }

  &.active {
    > div {
      &:nth-child(1) {
        transform: translateY(10px) rotate(135deg); }

      &:nth-child(2) {
        opacity: 0; }

      &:nth-child(3) {
        transform: translateY(-12px) rotate(-135deg); } } } }

/* Header Two */

.home-two {
  /* Main Menu */
  #dt-header {
    position: static; }
  #navigation-menu {
    background-color: #FFF;

    .navbar {

      .navbar-header {

        .brand-logo {
          transition: all 0.3s ease-in-out;

          .transparent-logo {
            display: none; }

          .normal-logo {
            display: block; } } }

      .navbar-nav {
        li {
          transition: all 0.3s ease-in-out;
          a {
            color: #6f6c6c;

            &:hover {
              color: $color-theme; }

            &.top-search {
              &:before {
                background: #6f6c6c; } } }
          &.border-right {
            &:before {
              background: #6f6c6c; } }

          .form-search-submit {
            color: #000; }

          .search-input-open {

            border-color: #6f6c6c; }

          .child-menu {

            li {
              a {
                color: #FFF; } } } } } } }



  /* Top Menu */
  .navbar-fixed-top {
    position: fixed !important;
    z-index: 1200;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    #navigation-menu {
      background-color: #FFF;

      .dropdown-menu {
        top: 48px; }

      .navbar {

        .navbar-header {

          .brand-logo {
            transition: all 0.3s ease-in-out; } } } } } }

/* Header Three */
.home-three {
  #dt-header {
    #top-menu {
      display: none; }

    .navbar {
      .navbar-nav {
        li {} } } }


  .navbar-fixed-top {
    transform: translateY(0);
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);

    .navbar {

      .navbar-header {

        .brand-logo {
          transition: all 0.3s ease-in-out; } } } } }






