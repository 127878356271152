/* map */
.google-map {}

.map {
  text-align: center;
  padding: 40px 0;
  background: $color_theme;

  button {
    color: #FFF;
    background-color: transparent;
    border: none;
    outline: none;

    i {
      padding-left: 5px; }

    &:hover {
      background: none; } } }




.gmap3-area {
  height: 400px; }

.show-map {
  height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &.map-open {
    height: 400px;
    opacity: 1; } }
