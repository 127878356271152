#top-menu {
  padding: 13px 0;
  background: #111111;

  .top-contact-info {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;
      @include font-size(14);
      color: #FFF;
      @include line-height(26);

      i {
        padding-right: 10px; }

      &:first-child {
        padding-right: 100px; } } }
  .dt-social-link {
    float: right; } }

.dt-social-link {
  margin: 0;
  padding: 0;
  list-style: none;


  li {
    display: inline-block;
    @include font-size(14);
    @include line-height(26);
    padding: 0 12px;
    text-decoration: none;
    &:last-child {
        padding: 0; }
    a {
      color: #FFF;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }
