.widget_dt_search {

  .search-form {
    width: 100%;

    label {
      position: relative;
      width: 270px; }


    .search-icon {
      position: absolute;
      right: 6px;
      top: 12px; } }

  input[type="search"] {
    width: 100%;
    border: 1px solid #eeeeee;
    outline: none;
    padding: 10px 20px;
    color: #c2c2c2;
    @include font-size(14); }



  .search-submit {
    display: none; } }
