/* Our Feature */
#our-featured {
  padding-top: 100px; }

.section-title {
  @include font-size(32);
  font-weight: 700;
  color: #111111;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    background: $color_theme;
    height: 5px;
    width: 50px;
    bottom: 0;
    position: absolute;
    left: 0; } }

.feature-item {
  margin-top: 60px;

  .featured {
    margin-bottom: 77px;
    cursor: pointer;
    .item-icon {
      padding: 5px;
      display: inline-block;
      height: 95px;
      width: 95px;
      border-radius: 50%;
      text-align: center;
      float: left;
      border: 1px solid $color_theme;
      transition: all 0.5s ease-in-out;

      i {
        font-size: 35px;
        color: #FFF;
        background: $color_theme;
        height: 83px;
        width: 83px;
        border-radius: 50%;
        @include line-height(34); } }

    .feature-content {
      margin-left: 115px;
      h3 {
        color: #111;
        font-size: 20px;
        font-weight: 700;
        margin-top: 8px;
        margin-bottom: 15px; }

      p {
        @include line-height(26);
        color: #7e7e7e; } }

    &:nth-child(5) {
      margin-bottom: 0; }

    &:hover {
      .item-icon {
        background: $color-theme; } } } }


