/* tabs */
#dt-tabs {
  padding: 50px 0;

  .sm-btn {
    margin-left: 50px; }

  .btn-skew {
    &:after {
      height: 60px; }

    &:hover {
      &:after {
        height: 130px; } } } }

.tabs-items {
  border-right: 1px solid #ededed;

  .dt-tabs-nav {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      a {
        text-decoration: none; }

      &.active {
        .icon-box {
          background: #d7eeff;

          &:after {
            background: #97d2ff; }
          i {
            background: $color_theme;
            color: #FFF; } } } } }


  .tabs {
    text-align: center;
    margin-bottom: 70px;
    position: relative;

    h3 {
      color: #111;
      @include font-size(16); } }

  .icon-box {
    height: 98px;
    width: 98px;
    float: none;
    background: #eeeeee;
    margin: 0 auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:after {
      height: 86px;
      width: 86px;
      left: 6px;
      top: 6px;
      background: #f5f5f7;
      transition: all 0.3s ease-in-out; }

    i {
      height: 74px;
      width: 74px;
      left: 12px;
      top: 12px;
      color: #111;
      @include line-height(35);
      background: #f5f5f7;
      transition: all 0.3s ease-in-out; }

    &:hover, {
      background: #d7eeff;

      &:after {
        background: #97d2ff; }

      i {
        background: $color_theme;
        color: #FFF; } } } }


.tabs-content {
  padding: 0 50px;

  .desing-caption {
    h3 {
      @include font-size(36);
      color: #111;
      margin-top: 90px; }

    span {
      color: $color_theme;
      letter-spacing: 2px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 25px;
      display: block; }

    p {
      color: #6d6c6c;
      line-height: 26px;
      margin-bottom: 40px; } } }

.tabs-details {
  margin: 0 0 40px 0;
  padding: 0;
  list-style: none;

  li {
    padding: 10px 0;
    @include font-size(16);
    color: #111;
    width: 50%;
    float: left;

    i {
      padding-right: 10px;
      color: $color_theme; } } }
