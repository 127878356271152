/* Service */

#our-service {
  position: relative;
  padding: 100px 0;
  text-align: center;
  overflow: hidden;

  .section-title {
    margin-bottom: 70px;

    &:after {
      left: 40%; } } }

.service {
  .service-box {

    h3 {
      color: #111111;
      @include font-size(24);
      margin-bottom: 35px; }

    p {
      color: #7e7e7e; } } }

