/* Widget Resent Post */
.widget_recent_entries {
  .resent-post {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .post-thumbs {
      float: left; }

    .post-content {
      margin-left: 90px;

      h5 {
        @include font-size(14);

        margin-top: 0;
        a {
          color: #111111;
          @include line-height(24);
          text-decoration: none;
          transition: all 0.3s ease;
          &:hover {
            color: $color_theme; } } }

      .post-meta {
        padding: 0;
        margin-left: 0;
        list-style: none;
        li {
          display: inline;
          padding-right: 10px;

          a {
            color: $color_theme;
            text-decoration: none;

            i {
              padding-right: 10px; } } } } } } }
