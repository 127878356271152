/* Team Two */

#team-two {
  position: relative;
  padding: 50px 0 80px;
  background-size: cover;
  background-repeat: no-repeat;


  .team-two-caption {
    margin-bottom: 50px;
    text-align: left;

    h1 {
      color: #FFF;
      font-weight: 700;
      @include font-size(36);

      span {
        font-weight: 400; } }

    p {
      color: #FFF;
      @include line-height(26); } }

  .gallery-top, .gallery-thumbs {
    overflow: hidden; }

  .gallery-top {

    .banner-next,.banner-prev {
      right: 22%;

      .next-btn ,.prev-btn {
        width: 24px;
        height: 22px; }

      &:before, &:after {
        height: 7px; } }


    .banner-next {
      top: auto;
      transform: rotate(90deg);

      .next-btn {
        transform: translateX(-10px); }

      &:hover {
        .next-btn {
          transform: translateX(-5px); } } }

    .banner-prev {
      transform: rotate(90deg);
      top: 20%;
      left: auto;

      .prev-btn {
        transform: translateX(10px); }

      &:hover {
        .prev-btn {
          transform: translateX(5px); } } } }



  .gallery-thumbs {
    width: 100px;
    height: 330px;
    position: absolute;
    right: 20%;
    top: 30%;

    .swiper-slide {
      height: 100%;
      opacity: 0.3; }

    .swiper-slide-active {
      opacity: 1; } }





  .swiper-slide {
    img {
      float: left; }

    .slider-content {
      margin-left: 310px;

      h4 {
        @include font-size(24);
        font-weight: 700;
        color: $color_theme; }


      span {
        color: #c8c8c8;
        display: block;
        margin-bottom: 30px; }

      p {
        color: #c8c8c8;
        @include line-height(26);
        margin-bottom: 50px; }

      .dt-social-link {} } } }

