html {
  font-size: 14px; }

body {
  @include font-smoothing;
  color: $color_text;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  backface-visibility: hidden; }

[data-bg-image] {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
