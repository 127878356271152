/* Client */
#client {
  padding: 50px 0;
  text-align: center;

  .client-caption {
    margin-bottom: 50px; }

  .swiper-slide {
    padding: 0 150px;
    text-align: left;
    .client-comments {
      padding: 35px;
      background-color: #f5f5f7;
      margin-bottom: 20px;
      position: relative;

      p {
        color: #7e7e7e;
        line-height: 26px; }

      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 16px solid #e5e5e5;
        border-left: 26px solid transparent;
        bottom: -15px;
        left: 0; } } }


  .next-btn {
    background-image: url(../img/right-arrow-three.svg); }

  .prev-btn {
    background-image: url(../img/left-arrow-three.svg); }

  .banner-prev {
    left: 0; }

  .banner-next {
    right: 0; }

  .banner-next, .banner-prev {
    border-color: #e5e5e5;
    top: 25%;
    transition: all 0.5s ease;

    &:after, &:before {
      background: #e5e5e5; }

    &:hover {
      border-color: $color_theme;

      &:after, &:before {
        background: $color_theme; }

      .prev-btn {
        background-image: url(../img/left-arrow-two.svg); }

      .next-btn {
        background-image: url(../img/right-arrow-two.svg); } } } }



.client-details {
  .client-thumb {
    float: left;
    height: 98px;
    width: 98px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
    padding: 5px;

    img {
      width: 100%;
      border-radius: 5px; } }

  .details {
    padding-left: 110px;

    h5 {
      color: $color_theme;
      font-size: 18px; }

    p {
      color: #6d6c6c; }

    .dt-social-link {
      display: inline-block;
      li {
        padding: 0 5px;
        a {
          color: #c6c6c6;

          &:hover {
            color: $color_theme; } } } } } }



