#team {
  text-align: center;
  padding: 100px 0;
  overflow: hidden;

  .section-title {
    margin-bottom: 50px;

    &:after {
      left: 43%; } } }

.team {
  .team-member {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%; }

    .overlay {
      padding: 20px 30px;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      .dt-social-link {
        transform: translateX(150px);
        transition: all 0.5s ease;

        li {
          padding: 0 6px; } }

      .name {
        position: absolute;
        bottom: 30px;
        right: 30px;
        text-align: right;
        transform: translateY(85px);
        transition: all 0.5s ease;

        h3 {
          color: $color_theme;
          font-weight: 700;
          margin-top: 0; }

        p {
          color: #FFF;
          margin-bottom: 0; } } }

    &:hover {
      .overlay {
        opacity: 1;

        .dt-social-link {
          transform: translateX(52px); }

        .name {
          transform: translateY(0); } } } } }
