/* Contact */
.contact {
  padding: 50px 0;
  .contact-title {
    h3 {
      position: relative;
      @include font-size(26);
      color: #111;
      margin-bottom: 30px;
      font-weight: 700;
      padding-bottom: 15px;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        width: 50px;
        background: $color_theme;
        left: 0;
        bottom: 0; } } }

  .form-input {
    position: relative;

    label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: #FFF;
      padding: 0 10px; }

    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #c2c2c2;
      outline: none;
      transition: all 0.15s ease-in-out;

      &:focus {
        border-color: $color_theme; } } }
  .textarea {
    position: relative;

    label {
      position: absolute;
      top: -10px;
      left: 20px;
      padding: 0 10px;
      background: #FFF; }

    textarea {
      width: 100%;
      height: 185px;
      padding: 15px 20px;
      outline: none;
      transition: all 0.15s ease-in-out;

      &:focus {
        border-color: $color_theme; } } }

  .contact-btn {
    background: $color_theme;
    padding: 13px 0;
    display: block;
    text-align: center;
    color: #FFF;
    margin-top: 20px;
    text-decoration: none;
    width: 100%;
    border: 1px solid $color_theme;
    transition: all 0.15s ease-in-out;

    &:hover,
    &:focus, {
      color: $color_theme;
      background-color: transparent;
      outline: none; }

    > i {
      display: none; }

    &.clicked {
      > i {
        display: block; }
      > span {
        display: none; } } } }


.contact-address {
  .contant-info {
    margin-bottom: 20px;
    .icon {
      float: left;
      height: 50px;
      width: 50px;
      text-align: center;

      i {
        @include font-size(40);
        padding-right: 15px;
        color: $color_theme; } } }

  .gmap3-area {
    border: 4px solid #f5f5f7; } }
