/* Header */
#mobile-wrapper {
  display: none; }

#mobile-header {
  text-align: center;
  background: #222533;
  padding: 8px 10px;
  height: 50px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10000;

  .main-logo {
    width: 100px;
    display: inline-block;
    img {
      width: 100%; } } }

.menu-container {
  float: right; }


#mobile-wrapper {
  width: 270px;
  background: #222533;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  height: 100vh;

  .mobile-menu-container {

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none; } } }



  #mobile_menu_search {
    padding: 12px 0;
    text-align: center;
    font: inherit;
    background: #2b2b2b;
    margin-bottom: 20px;
    #search {
      form {
        input, #searchsubmit {
          border: 1px solid rgba(145,157,201,.14);
          background: #19172f;
          padding: 10px;
          outline: none;
          border-right: none;
          color: #FFF;

          #searchsubmit {
            border-left: none;
            border-right: 1px solid rgba(145,157,201,.14);
            margin-left: -5px; } } } } }

  hr {
    border-color: #545454; } }


/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: $color_theme;
  width: 40px;
  height: 40px;
  z-index: 1000;
  text-decoration: none;
  opacity: 0;
  transition: all 0.3s ease;

  &.visible {
    opacity: 1; }

  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }

/*=========  Jpush Menu  ==========*/

.cbp-spmenu-right {
  right: -270px; }

.cbp-spmenu-vertical {
  a {
    border-bottom: none;
    &:hover {
      background: none; } } }

.cbp-spmenu-push-toleft {
  &:before {
    background: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 10000; } }

.cbp-spmenu-push-toleft {
  left: -270px; }


/*=========  Acordian Mobile Menu  ==========*/

.accordion-menu {
  width: 100%;
  max-width: 350px;
  margin: 60px auto 20px;
  // background: #fff
  border-radius: 4px;

  > li {
    border-bottom: 1px solid #333;
    > a {

      font-weight: 500;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px; } } }



.accordion-menu li.open .dropdownlink {
  color: $color_theme;
  .fa-chevron-down {
    transform: rotate(180deg); } }


.accordion-menu li:last-child .dropdownlink {
  border-bottom: 0; }

.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 45px;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  color: #212121;
  position: relative;
  transition: all 0.4s ease-out;
  i {
    position: absolute;
    top: 13px;
    left: 16px;
    font-size: 14px; }

  .fa-chevron-down {
    right: 12px;
    left: auto; } }



.submenuItems {
  display: none;
  background: #0c0c0c;
  li {
    border-bottom: 1px solid #252525;

    &:last-child {
      border-bottom: none; } } }

.submenuItems a {
  display: block;
  color: #FFF;
  padding: 12px;
  transition: all 0.4s ease-out;
  text-decoration: none;

  &:hover, &:focus {
    background-color: $color_theme;
    color: #fff; } }

