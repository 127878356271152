/* Expart Team */
.expart-team {
  .team-member {
    margin-bottom: 30px;
    text-align: left;

    .name {
      margin-bottom: 100px;

      h3 {
        color: $color_theme;
        margin-top: 0;
        @include font-size(22);
        font-weight: 700; }

      p {
        color: #FFF;
        margin-bottom: 0; } }

    .overlay {
      top: 78%;
      opacity: 1;

      h3 {
        color: $color_theme;
        @include font-size(24);
        font-weight: 700;
        margin-top: 0; }

      span {
        color: #FFF;
        margin-bottom: 20px;
        display: block;
        padding-bottom: 20px;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          background: $color_theme;
          width: 50px;
          height: 4px;
          left: 0;
          bottom: 0;
          opacity: 0; } }

      p {
        color: #FFF; }

      .dt-social-link {
        transform: translateX(0) !important; } }

    &:hover {
      .overlay {
        top: 0;
        padding-top: 130px;

        span {
          &:after {
            opacity: 1; } } } } } }




