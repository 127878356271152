#about-us {
  padding-top: 100px;
  overflow: hidden;

  .about-image {
    width: 100%;
    height: auto; }

  .section-title {
    margin-bottom: 30px;
    margin-top: 60px; }

  p {
    margin-bottom: 60px; }


  #skills {
    position: relative;
    max-width: 550px; }

  .skills {
    position: relative;

    span {
      top: -60px;
      left: 2%;
      position: absolute;
      @include font-size(14);
      font-weight: 700;
      color: #fff; }
    p {
      position: absolute;
      right: 0;
      top: -90px;
      margin-bottom: 0; } } }



progress {
    background-color: #dbecfa;
    border: 0;
    height: 30px;
    width: 100%;
    margin-bottom: 30px; }


progress::-webkit-progress-bar {
    background-color: #dbecfa; }


progress::-webkit-progress-value {
    background-color: #36a8ff; }


progress::-moz-progress-bar {
    background-color: #36a8ff; }

.home-three {
  #about-us {
    .section-title {
      margin-top: 0; } } }
