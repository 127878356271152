/* Feature Three */

#feature-top-three {
  padding: 100px 0 40px;
  background: #f5f5f7;

  .feature-items {
    .box {
      cursor: pointer;
      margin-bottom: 60px;

      .feature-thumb {
        float: left;
        position: relative;
        text-align: center;

        .overlay {
          background-color: rgba(54, 168, 255, .5);
          padding: 35px 0;
          transform: scale(0);
          transition: all 0.3s ease-in-out;

          a {
            @include font-size(30);
            color: #FFF;
            display: block; } } }

      .feature-content {
        margin-left: 200px;

        h3 {
          margin-top: 0;
          color: #111;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
          @include font-size(20); }

        p {
          line-height: 26px; } }

      &:hover {
        .feature-thumb {
          .overlay {
            transform: scale(1); } }

        .feature-content {
          h3 {
            color: $color_theme; } } } } } }


