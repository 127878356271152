/* Contact Form */
#contact-form {
  padding: 80px 0;
  overflow: hidden;

  .dt-btn {
    position: relative;
    padding: 8px 60px;
    @include font-size(16);
    margin-left: 15px;
    bottom: 0; }

  .btn-skew {
    &:after {
      height: 85px; }

    &:hover {
      &:after {
        height: 200px; } } }

  .full {
    position: relative;

    label {
      position: absolute;
      left: 30px;
      top: -10px;
      background: #FFF;
      padding: 0 10px;
      color: #7e7e7e; }

    .dt-massage {
      width: 100%;
      height: 170px;
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #747474;
      background-color: transparent; } }

  .call-back-form {
    margin-top: 0;

    .half-input {
      label {
        background: #FFF;
        color: #7e7e7e; }

      .call-back-input {
        color: #000; } } } }

.contact-caption {
  padding-left: 10px;

  h3 {
    color: #111;
    font-weight: 700;
    @include font-size(26);
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;

    span {
      font-weight: 400; } }

  p {
    margin-bottom: 35px;
    @include line-height(26);
    color: #000; }

  .half-input {
    .call-back-input {
      border: 1px solid #747474; } } }


.map-two {
  margin-top: 130px; }
