/*!
  Theme Name: Corpo
  Theme URI: http://www.decentthemes.com/products/corpo-html/
  Author: Decent Themes
  Author URI: http://www.decentthemes.com/
  Description: The Ultimate Financial / Business Template
  Version: 1.2.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: corpo
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base rules
  # Library
    ## Example
  # Elements
    ## Accessibility
    ## Buttons
  # Navigation Menu
    # Top Menu
    # Primary Menu
  # Sections
    ## Header
    ## Footer
    ## Banner
    ## Feature
    ## Our Feature
    ## Finance
    ## About
    ## Our Service
    ## Our Feedback
    ## Our Team
    ## Call Back
    ## Choose
    ## Letest News
    ## Brand
    ## Contact Form
    ## Map
    ## Our Feature Two
    ## Theme
    ## Client
    ## About Two
    ## Choose Two
    ## Team
    ## Team Two
    ## Design
    ## feature top
    ## Our feature three
    ## Service Banner
    ## Expart Details
    ## Service Banner
    ## Insurance
    ## News All
    ## News All Two
    ## Blog Three
    ## Contact
    ## Footer
    ## Responsive
  # Widgets
    ## Search
    ## Dt Custom Menu
    ## Dt Download Menu
    ## Dt Tags
    ## Dt Resent Post
    ## Dt Archive
  --------------------------------------------------------------*/

// Variables and Mixins
@import "base/variables";
@import "base/mixins";

/*--------------------------------------------------------------
  # Base rules
  --------------------------------------------------------------*/
@import "base/bootstrap";

/*--------------------------------------------------------------
  # Elements
  --------------------------------------------------------------*/
@import "elements/elements";

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
@import "navigation-menu/navigation";

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
@import "sections/sections";

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
@import "widgets/widgets";
