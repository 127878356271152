/* Team Details */
.team-details {
  padding: 100px 0;

  .team-thumb {
    float: left; }

  .team-caption {
    margin-left: 315px;

    h3 {
      @include font-size(24);
      font-weight: 700;
      color: #111;
      margin-top: 0; }

    .content {
      border-top: 1px solid #f8f8fa;
      border-bottom:  1px solid #f8f8fa;
      border-right:  1px solid #f8f8fa;
      padding: 10px 20px 37px 0;

      span {
        display: block;
        color: $color_theme;
        margin-bottom: 30px; }

      p {
        @include line-height(26); }

      .dt-social-link {
        padding-top: 20px;
        li {
          padding: 0 7px;
          a {
            color: #c2c2c2;

            &:hover {
              color: $color_theme; } } } } } } }
