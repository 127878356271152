.intro-feature {
  padding: 100px 0;
  text-align: center;


  h2 {
    text-transform: uppercase;
    margin-bottom: 40px;
    color: #000;
    position: relative;
    display: inline-block;
    padding-bottom: 15px;

    &:after {
      position: absolute;
      content: '';
      left: 35%;
      bottom: 0;
      height: 4px;
      width: 50px;
      background: $color_theme; } }

  .multi-page {
    position: relative;
    margin-bottom: 30px;
    .page-thumb {
      display: inline-block;
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.08);
      margin-bottom: 30px;
      img {
        width: 100%; }

      &:hover {
        box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.28);
        transform: translateY(-10px); } }

    .dt-btn {
      border-color: #111;
      color: #111;

      &:hover {
        border-color: $color_theme;
        color: #FFF; } } } }
