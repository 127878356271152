/* Feature */
.feature {
  text-align: center;
  position: relative;
  overflow: hidden;
  img {
   width: 100%; }
  h3 {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    @include font-size(20);
    font-weight: 700;
    margin: 0; }

  .overlay {
    transition: all 0.3s ease-in-out; }

  .overlay-content {
    display: block;
    background-color: rgba(54, 168, 255, 0.6);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    text-align: left;

    transform: translateY(100%);
    transition: all 0.3s ease-in-out;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-25%, -50%);

      h2 {
        color: #FFF;
        @include font-size(20);
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 26px;
        transform: translateY(200px);
        transition: all 0.5s ease-in-out; }

      p {
        color: #FFF;
        @include line-height(26);
        margin-bottom: 30px;
        transform: translateY(200px);
        transition: all 0.7s ease-in-out; }

      .dt-btn-white {
        padding: 9px 27px;
        color: #FFF;
        @include font-size(14);
        @include line-height(14);
        transform: translateY(200px);
        transition: all 0.9s ease-in-out; } } }

  &:hover {
    .overlay {
      transform: translateY(-100%); }

    .overlay-content {
      transform: translateY(0);
      .content {
        h2 {
          transform: translateY(0px); }


        p {
          transform: translateY(0px); }

        .dt-btn-white {
          transform: translateY(0px); } } } } }

