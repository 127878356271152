/* Choose */
#choose-us {
  text-align: center;
  padding-top: 100px;

  .section-title {
    &:after {
      left: 44%; } } }

.choose-wrapper {
  overflow: hidden; }

.choose-item {
  margin-top: 150px; }

.category {
  margin-bottom: 50px;
  text-align: left;

  .serial {
    @include font-size(50);
    color: #eeeeee;
    position: absolute;
    margin-bottom: 0;
    margin-top: 10px; } }

.icon-box {
  display: block;
  float: left;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  position: relative;
  margin-left: 50px;
  margin-right: 20px;
  text-align: center; }

.pink, .green, .red {

  &:after {
    content: '';
    position: absolute;
    height: 76px;
    width: 76px;
    left: 6px;
    top: 6px;
    border-radius: 50%; }

  i {
    font-size: 30px;
    color: #FFF;
    position: absolute;
    height: 64px;
    width: 64px;
    top: 12px;
    left: 12px;
    border-radius: 50%;
    z-index: 100;
    @include line-height(30); } }

.pink {
  background-color: rgba(199, 68, 253, 0.2);

  &:after {
    background-color: rgba(199, 68, 253, 0.4); }

  i {
    background-color: rgb(199, 68, 253); } }

.green {
  background-color: rgba(46, 184, 81, 0.2);

  &:after {
    background-color: rgba(46, 184, 81, 0.4); }

  i {
    background-color: rgb(46, 184, 81); } }

.red {
  background-color: rgba(240, 90, 40, 0.2);

  &:after {
    background-color: rgba(240, 90, 40, 0.4); }

  i {
    background-color: rgb(240, 90, 40); }


  .content {

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #111111;
      margin-top: 0; }

    p {
      margin-bottom: 0;
      color: #7e7e7e;
      @include line-height(26); } } }

